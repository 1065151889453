import { NotificationType } from '@@/components/Notifications/Notification';
import { Dispatch } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { dismissNotification, NotificationProps } from '@/stores/notificationsSlice';
import { RootState } from '@/stores/store';

export const useNotifications: () => NotificationType[] = () => {
	const { notifications } = useSelector((state) => (state as RootState).notifications);
	const filteredNotificaitons = notifications.filter(
		(notification: NotificationProps, index: number, self: NotificationProps[]) =>
			index ===
			self.findIndex(
				(n) => JSON.stringify(n.message) === JSON.stringify(notification.message)
			)
	);
	return filteredNotificaitons;
};

export const onDismiss = (id: string, dispatch: Dispatch) => {
	dispatch(dismissNotification(id));
};
