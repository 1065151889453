import { lazy } from 'react';

const Registration = lazy(() =>
	import('@/features/registration').then(({ Registration }) => ({ default: Registration }))
);

export const publicRoutes = [
	{
		path: '/*',
		element: <Registration />,
	},
];
