import { Transition } from '@headlessui/react';
import { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';

import { Alert } from '../Elements/Alert';

export type NotificationType = {
	id: string;
	type: 'info' | 'success' | 'warning' | 'error';
	title: string;
	message?: string;
	timeoutLength?: number;
};

export type NotificationProps = {
	notification: NotificationType;
	onDismiss: (id: string, dispatch: Dispatch) => void;
};

export const Notification = ({
	notification: { id, type, title, message = '', timeoutLength = 8000 },
	onDismiss,
}: NotificationProps) => {
	const dispatch = useDispatch();

	useEffect(() => {
		setTimeout(() => {
			onDismiss(id, dispatch);
		}, timeoutLength);
	}, [onDismiss, id, timeoutLength]);	

	return (
		<Transition
			show={true}
			as={Fragment}
			enter='transform ease-out duration-300 transition'
			enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
			enterTo='translate-y-0 opacity-100 sm:translate-x-0'
			leave='transition ease-in duration-100'
			leaveFrom='opacity-100'
			leaveTo='opacity-0'
		>
			<div className='max-w-sm pointer-events-auto overflow-hidden' role='alert'>
				<Alert
					severity={type}
					title={title}
					showCloseButton={true}
					handleClose={() => {
						onDismiss(id, dispatch);
					}}
				>
					{message}
				</Alert>
			</div>
		</Transition>
	);
};
