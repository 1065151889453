import {
	createListenerMiddleware,
	isAnyOf,
	isRejectedWithValue,
	Middleware,
	MiddlewareAPI,
} from '@reduxjs/toolkit';

import { addNotification } from '@/stores/notificationsSlice';
import { formatErrorCodes } from '@/utils';

import { saveContactDetails, saveIsVerified } from './regristrationSlice';
import type { RootState } from './store';

export const listenerMiddleware = createListenerMiddleware();
listenerMiddleware.startListening({
	matcher: isAnyOf(saveContactDetails, saveIsVerified),
	effect: (action, listenerApi) =>
		sessionStorage.setItem(
			'registrationData',
			JSON.stringify((listenerApi.getState() as RootState).registrationData)
		),
});

export const rtkQueryErrorHandler: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
	if (isRejectedWithValue(action) && action.payload.status !== 401) {
		if (Array.isArray(action.payload.data)) {
			action.payload.data.map((err: object) => {
				api.dispatch(
					addNotification({
						type: 'error',
						title: 'Error',
						message: formatErrorCodes({ status: action.payload.status, data: err }),
						timeoutLength: 8000,
					})
				);
			});
		} else {
			api.dispatch(
				addNotification({
					type: 'error',
					title: 'Error',
					message: formatErrorCodes(action.payload),
					timeoutLength: 8000,
				})
			);
		}
	}
	return next(action);
};
