import { Notification, NotificationType } from './Notification';
import { Dispatch } from '@reduxjs/toolkit';

type NotificationsProps = {
	notificationsList: NotificationType[],
	onDismiss: (id: string, dispatch: Dispatch) => void;
};
export const Notifications = (props: NotificationsProps) => {
	const { notificationsList, onDismiss } = props;
	return (
		<>
			<div
				aria-live='assertive'
				className='fixed inset-0 flex flex-col items-end px-4 py-6 pointer-events-none sm:items-start sm:p-6 z-40'
			>
				<div className='flex flex-col items-center space-y-4 w-full  sm:items-end'>
					{notificationsList?.map((notification: NotificationType) => {
						return (
							<Notification
								key={notification.id}
								notification={notification}
								onDismiss={onDismiss}
							/>
						);
					})}
				</div>
			</div>
		</>
	);
};
