import { FormValues } from '@@/components/Form';
import { formatPhoneNumberDisplay, formatPhoneNumberValue } from '@stern-ui/utilities';

import { HTTP_ERRORS, isUs, phoneNumberPrefix } from '@/globalConsts';

export const formatErrorCodes = (payload: { status: number | string; data: object }) => {
	if (payload.data !== null) {
		// when API returns 404 error page.
		return payload.status === 'PARSING_ERROR' ? HTTP_ERRORS[404] : Object.values(payload.data);
	} else
		return Object.keys(HTTP_ERRORS).includes(payload.status + '')
			? HTTP_ERRORS[payload.status]
			: 'Something went wrong. Please try again later.';
};

export const formatPatientRegistrationValues = (valuesObj: FormValues) => {
	// strip out any non-numeric character
	const mobile_number = formatPhoneNumberValue(
		typeof valuesObj.mobile_number === 'string' ? valuesObj.mobile_number : undefined,
		phoneNumberPrefix,
		isUs
	);

	const returnObj: FormValues = {
		cohort: valuesObj.cohort,
		hci: valuesObj.hci,
		first_name: valuesObj.first_name,
		middle_name: valuesObj.middle_name,
		last_name: valuesObj.last_name,
		email: valuesObj.email,
		mobile_number: mobile_number,
		contact_opt_in: valuesObj.contact_opt_in,
	};

	return returnObj;
};

export const formatUpdatePatientRegistrationValues = (valuesObj: FormValues) => {
	const home_phone = formatPhoneNumberValue(
		typeof valuesObj.home_phone === 'string' ? valuesObj.home_phone : undefined,
		phoneNumberPrefix,
		isUs
	);

	const returnObj: FormValues = {
		date_of_birth: valuesObj.date_of_birth,
		home_phone: home_phone,
		legal_sex: valuesObj.legal_sex,
		state: valuesObj.state,
	};

	return returnObj;
};

export const maskSensitiveInfo = (input: string): string => {
	if (!input) return '';

	// Check if the input is an email
	if (input.includes('@')) {
		const [localPart, domain] = input.split('@');
		const maskedLocalPart =
			localPart.length > 2
				? localPart[0] + '*'.repeat(localPart.length - 2) + localPart.slice(-1)
				: '*'.repeat(localPart.length);
		return `${maskedLocalPart}@${domain}`;
	}

	// Otherwise, assume it's a phone number
	const maskedPhoneNumber = '*'.repeat(input.length - 3) + input.slice(-3);

	const formattedMaskedPhoneNumber = formatPhoneNumberDisplay(
		maskedPhoneNumber,
		phoneNumberPrefix,
		isUs
	);

	return formattedMaskedPhoneNumber ? formattedMaskedPhoneNumber : '';
};
