import { Notifications as NotificationsSharedComponent } from '@@/components/Notifications';

import { useNotifications, onDismiss } from '@/utils/notifications';

export const Notifications = () => {
	const notificationsList = useNotifications();
	return (
		<NotificationsSharedComponent notificationsList={notificationsList} onDismiss={onDismiss} />
	);
};
