export const formatPhoneNumberDisplay = (phoneNumber: string | undefined, phoneNumberPrefix: string, isUs: boolean) => {
	// Format phone numbers for display, remove any country code prefixs,
	//add a leading 0 if a UK number or format as (XXX) XXX-XXXX if a US number
	if (phoneNumber && phoneNumber.length >= 1) {
		const trimmedPhoneNumber = phoneNumber.slice(phoneNumberPrefix.length);
		if (isUs) {
			return `(${trimmedPhoneNumber.slice(0, 3)}) ${trimmedPhoneNumber.slice(
				3,
				6
			)}-${trimmedPhoneNumber.slice(6, 10)}`;
		} else {
			return '0' + trimmedPhoneNumber;
		}
	}
};

export const formatPhoneNumberValue = (phoneNumber: string | undefined, phoneNumberPrefix: string, isUs: boolean): string => {
	if (!phoneNumber) return '';
	const cleanedNumber = phoneNumber.replace(/\D/g, '');
	return cleanedNumber.length >= 1
		? phoneNumberPrefix + cleanedNumber.slice(isUs ? 0 : 1)
		: cleanedNumber;
};