import { Helmet } from 'react-helmet-async';

import { AppProvider } from '@/providers/app';
import { AppRoutes } from '@/routes';

const App = () => {
	return (
		<AppProvider>
			<Helmet />
			<AppRoutes />
		</AppProvider>
	);
};

export default App;
