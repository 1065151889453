import { Typography } from '../Typography';
import clsx from 'clsx';
import AlertOutline from 'mdi-material-ui/AlertOutline';
import Check from 'mdi-material-ui/Check';
import Close from 'mdi-material-ui/Close';
import InformationVariant from 'mdi-material-ui/InformationVariant';

export type AlertProps = {
	severity: keyof typeof severityOptions;
	title?: string;
	showCloseButton?: boolean;
	handleClose?: () => void;
	children: string | string[];
};

const severityOptions = {
	info: {
		icon: <InformationVariant className='text-info' aria-hidden='true' />,
		iconBg: 'bg-highlight',
		border: 'border-info',
	},
	success: {
		icon: <Check className='text-success' aria-hidden='true' />,
		iconBg: 'bg-highlight',
		border: 'border-success',
	},
	warning: {
		icon: <AlertOutline className='text-warning' aria-hidden='true' />,
		iconBg: 'bg-highlight',
		border: 'border-warning',
	},
	error: {
		icon: <Close className='text-error' aria-hidden='true' />,
		iconBg: 'bg-highlight',
		border: 'border-error',
	},
};

export const Alert = (props: AlertProps) => {
	const { severity = 'success', title, showCloseButton, handleClose, children } = props;

	const renderTitle = () => {
		if (title) {
			return (
				<Typography variant='title4' className='mb-1'>
					{title}
				</Typography>
			);
		}
	};

	return (
		<div
			role='alert'
			className={clsx(
				'flex flex-row items-start bg-white p-4 rounded border border-solid w-full',
				severityOptions[severity].border
			)}
			data-test-id={`alert-${severity}`}
		>
			<div className={clsx('pr-6 flex-shrink-0', title ? 'mt-1' : '')}>
				<div
					className={clsx(
						'rounded-full w-[40px] h-[40px] flex justify-center items-center',
						severityOptions[severity].iconBg
					)}
					aria-hidden='true'
				>
					{severityOptions[severity].icon}
				</div>
			</div>
			<div className='flex-1'>
				{renderTitle()}
				<Typography variant='body' className='text-secondaryTextDark'>
					{children}
				</Typography>
			</div>
			{showCloseButton && handleClose && (
				<div className='ml-4 flex-shrink-0 flex'>
					<button
						type='button'
						className='inline-flex focus:outline-none focus:ring-2 focus:ring-primaryBrand rounded-full p-2 -mt-2 -mr-2 '
						onClick={handleClose}
					>
						<span className='sr-only'>Close</span>
						<Close
							className='text-primaryTextDark'
							fontSize='small'
							aria-hidden='true'
						/>
					</button>
				</div>
			)}
		</div>
	);
};
